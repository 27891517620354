.welcome-modal .header {
	text-align: center;
}
.welcome-modal .actions {
	background: #f6f9fc;
}
.slick-prev::before,
.slick-next::before {
	color: var(--dark) !important;
}
.slick-prev {
	left: 0px;
}
.slick-next {
	right: 0px;
}
.slider-inner-content {
	margin: 30px 10%;
	width: 80% !important;
	text-align: center;
}
.slick-arrow {
	z-index: 999;
}
.slick-arrow::before {
	color: #191935 !important;
}
.slider-inner-content h3 {
	font-size: 30px;
	color: #3b4170 !important;
}
.slider-inner-content p {
	font-size: 17px;
	color: #8898aa !important;
}
.slick-dots {
	margin: 20px 0 23px 0;
}

.ui.modal > .image.content {
	display: grid !important;
	grid-template-columns: 1fr 300px;
}
.welcome-modal .ui.medium.image {
	padding: 20px 0 0 20px;
}
.welcome-modal .ui.medium.image img {
	width: 350px !important;
}
.slick-slider {
	height: 100%;
}
