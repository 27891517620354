/* App Header */
.app-header {
	background: #667585;
	padding: 0;
	box-shadow: 0 5px 4px 0 rgba(43, 44, 80, 0.07);
	align-self: center;
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
}
.app-header h1 {
	margin: 0;
	min-height: 51px;
	padding: 10px 10px 9px 0;
	font-family: 'Muli', sans-serif;
	font-size: 40px;
	font-weight: 300;
	text-transform: uppercase;
	font-size: 25px;
	color: #ffffff;
	letter-spacing: 1px;
}
.app-header .ui.menu .item {
	padding: 10px 20px;
}
.app-header .ui.menu .dropdown.item .menu {
	background: #272849;
	min-width: 200px;
}
.app-header .ui.menu .dropdown.item.active {
	background: #1b1b38;
}
.app-header .ui.menu .dropdown.item .menu a,
.app-header .ui.menu .dropdown.item .menu div {
	color: #ffffff !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.app-header .ui.menu .dropdown.item .menu a:hover,
.app-header .ui.menu .dropdown.item .menu div.active,
.app-header .ui.menu .dropdown.item .menu div:hover {
	color: #e5a03e !important;
}
.app-header .ui.menu .dropdown.item .menu div:last-child {
	border: none;
}
.menu-comp-selector .ui.item {
	min-width: 200px;
	justify-content: flex-end;
}

.logged-in-user-details {
	color: #ffffff;
	margin-right: 20px;
}
.sub-header h3 {
	margin: 0;
}
